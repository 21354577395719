<template>
  <div>
    <b-row class="no-gutters align-items-center">
      <b-col md="3" sm="12" lg="6">
        <h4 class="mr-sm-4 header-tablepage">
          {{ title }}
        </h4>
      </b-col>
      <b-col md="9" sm="12" lg="6" class="mt-2 mt-md-0">
        <b-input-group>
          <b-form-input
            type="search"
            class="search-bar"
            @keyup.enter="handleSearch"
            :placeholder="placeholder"
            v-model="filter.search"
            v-debounce:200ms="handleSearch"
          >
          </b-form-input>
          <b-input-group-append is-text class="mr-2">
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
          <router-link :to="link">
            <button type="button" class="btn button btn-mobile">
              <font-awesome-icon icon="plus" class="text-black d-sm-none" />
              <span class="d-none d-sm-block">
                <font-awesome-icon icon="plus-square" class="pointer" />
                Create New
              </span>
            </button>
          </router-link>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    filter: {
      required: true,
      type: Object,
    },
    link: {
      required: true,
      type: String,
    },
    placeholder: {
      required: true,
      type: String,
    },
  },
  methods: {
    handleSearch() {
      this.$emit("search", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
</style>
